// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const connectToDevelop = 'https://api.dev.syl20n.com/';
export const environment = {
  production: false,
  apiUrl: connectToDevelop,
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  firebase: {
    apiKey: "AIzaSyD7wdS0XU3oujAq1Y8Heur5v-Cx9qfgi30",
    authDomain: "snofy-api.firebaseapp.com",
    projectId: "snofy-api",
    storageBucket: "snofy-api.firebasestorage.app",
    messagingSenderId: "547353889043",
    appId: "1:547353889043:web:9eacfecd60a4f47ee832e6",
    measurementId: "G-0KL1C5NR9P",
    vapidKey: "BH2T8pZYoi2lR0zV39VWe4DWeCr09aZLXIpdzVTD__tf-PuHGh0zqAlEDFe7i-HYuvcmjW3rYwtDfUXkq35VqWU",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
