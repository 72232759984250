import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, shareReplay} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {User} from '../models/user';
import {CrudService} from './crud.service';
import {CodeValidation} from 'src/app/types/code-validation';
import {SchoolYear} from "../models/school-year";
import {UrlConfig} from "../url-config";
import {LocalStorageService} from "./local-storage-service";

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public currentUser: Observable<User>;
  payloads: any;
  config: any;
  entity: any;
  enptyObject: Object = {}; //Objet vide a utiliser pour les reinitialisations de pages
  private currentUserSubject: BehaviorSubject<User>;
  private currentSchoolYearSubject: BehaviorSubject<SchoolYear>;

  constructor(private crudService: CrudService, private localStorageService: LocalStorageService) {
    const storedUser = localStorage.getItem('currentUser');
    const storedSchoolYear = localStorage.getItem('currentSchoolYear');
    const user = storedUser ? JSON.parse(storedUser) : null;
    const schoolYear = storedSchoolYear ? JSON.parse(storedSchoolYear) : null;
    this.currentUserSubject = new BehaviorSubject<User>(user);
    this.currentSchoolYearSubject = new BehaviorSubject<SchoolYear>(schoolYear);
    this.currentUser = this.currentUserSubject.asObservable();
    this.config = UrlConfig;
    this.entity = this.config.user;
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public get currentSchoolYearValue(): User {
    return this.currentSchoolYearSubject.value;
  }

  getCurrentSchoolYears = (): void => {
    this.crudService.getEntities(this.entity.schoolYears).pipe(
      tap(response => {
        const currentSchoolYear: SchoolYear | undefined = response.find((year: SchoolYear) => year.currentYear === true);
        if (currentSchoolYear) {
          localStorage.setItem('currentSchoolYear', JSON.stringify(currentSchoolYear));
        }
      })
    ).subscribe();
  };

  login(data: any) {
    this.payloads = {
      usernameOrPhoneNumber: data.usernameOrPhoneNumber,
      password: data.password,
    };
    return this.crudService.addEntity(this.entity.login, this.payloads).pipe(
      tap((response: any) => {
        if (response.httpStatus === 'OK') {
          console.log('response: ', response);
          // Extraire les IDs des classes de la réponse API
          const classIds = response.data.teaches.map((teach: { classe: { id: number; }; }) => teach.classe.id);
          localStorage.setItem('currentUser', JSON.stringify(response));
          localStorage.setItem('currentSchool', JSON.stringify(response.data?.school));
          localStorage.setItem('accountUID', response.data?.account.accountUID);
          this.localStorageService.setCurrentSequenceValue(this.enptyObject);
          this.localStorageService.setCurrentProgramValue(this.enptyObject);
          // Stocker les IDs des classes dans le localStorage
          localStorage.setItem('classIds', JSON.stringify(classIds));

          console.log('Class IDs saved to localStorage:', classIds);
          this.currentUserSubject.next(response);
          this.crudService.currentUserValue();
          this.getCurrentSchoolYears()
        }
      }),
    );
  }

  logout(accountId: number) {
    const pathVariables = {userId: accountId};
    localStorage.removeItem('currentUser');
    this.localStorageService.setCurrentLanguageValue('en');
    this.localStorageService.setCurrentSchoolValue(this.enptyObject);
    this.localStorageService.setCurrentClassroomValue(this.enptyObject);
    this.localStorageService.setCurrentSchoolYearValue(this.enptyObject);
    this.localStorageService.setCurrentSectionValue(this.enptyObject);
    this.localStorageService.setCurrentSequenceValue(this.enptyObject);
    this.localStorageService.setCurrentProgramValue(this.enptyObject);
    return this.crudService.postWithUrlParams(this.entity.logout + '/{userId}', pathVariables).pipe();
  }

  recupererAnneeScolaires(): Observable<SchoolYear> {
    return this.getSchoolYears().pipe(
      map(
        schoolYears =>
          schoolYears.find((schoolYear: SchoolYear) => schoolYear.currentYear),
      ),
      tap(schoolYear => {
        if (schoolYear) {
          localStorage.setItem('currentSchoolYear', JSON.stringify(schoolYear));
        }
      }),
      shareReplay(1),
    );
  }

  getSchoolYears = () => this.crudService.getEntities(this.entity.schoolYears);

  /**
   * Mot de passe oublié
   * @param data
   * @returns
   */
  forgotPassword = (data: { usernameOrPhoneNumber: string; optionrecuperation: string }): Observable<any> =>
    this.crudService
      .addEntity(this.entity.forgotpassword, {
        usernameOrPhoneNumber: data.usernameOrPhoneNumber,
        recuperationOption: data.optionrecuperation,
      })
      .pipe();

  /**
   * Valider le code recu
   * @param codeValidation
   * @returns
   */
  codeValidation = (codeValidation: CodeValidation): Observable<any> =>
    this.crudService
      .addEntity(this.entity.codevalidation, {
        codeRecu: codeValidation.code,
        nouveauPassword: codeValidation.password,
        confirmationNouveauPassword: codeValidation.confirmPassword,
      })
      .pipe();

  uid = () => this.currentUserValue?.data?.account?.id ?? 0;
  etablissement = () => this.currentUserValue?.data?.school ?? {};
  identifiantEcole = () => this.currentUserValue?.data?.school?.id ?? 0;
  nomEcole = () => this.currentUserValue?.data?.school?.libelle ?? '';
  identifiantAnneeScolaire = () => this.currentSchoolYearValue?.id ?? 0;
  identifiantSection = () => 0; //a mettre a jour plus tards
  /* l'opérateur de coalescence ?? pour fournir une valeur par défaut dans le cas où la valeur est undefined
  si this.currentUserValue?.data?.account?.isTeacher est undefined, la valeur par défaut false sera utilisée. */
  isTeacher = () => this.currentUserValue?.data?.account?.isTeacher ?? false;
  isAdmin = () => this.currentUserValue?.data?.account?.isAdmin ?? false;
  isTutor = () => this.currentUserValue?.data?.account?.isTutor ?? false;

  checkEndPoint = (endpoint: string) =>
    !this.isTeacher ? endpoint : endpoint + '/' + Number(this.identifiantEcole());
  checkEndPointSchool = (endpoint: string) =>
    !this.isTeacher ? endpoint : endpoint + '/school/' + Number(this.identifiantEcole());
  checkEndPointSection = (endpoint: string) =>
    !this.isTeacher ? endpoint : endpoint + '/section/' + Number(this.identifiantSection());


  checkUserGrade = (): string => {
    return this.isTeacher() && this.isAdmin()
      ? 'GRADE.SCHOOL_ADMIN'
      : this.isTeacher()
        ? 'GRADE.TEACHER'
        : 'GRADE.STAFF';
  }
}

