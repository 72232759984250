import {Injectable, OnInit} from "@angular/core";
import {CrudService} from "./crud.service";
import {AuthService} from "./auth.service";
import {UrlConfig} from "../url-config";
import {Observable} from "rxjs";
import {SchoolYear} from "../models/school-year";

@Injectable({
  providedIn: 'root'
})
export class SchoolYearService implements OnInit {
  config: any;
  entity: any;
  payloads: any;
  currentUserSchoolId!: number;
  isTeacher!: boolean;

  constructor(private crudService: CrudService,
              private authService: AuthService) {
    this.entity = UrlConfig.schoolyear;
  }

  ngOnInit(): void {
    this.currentUserSchoolId = this.authService.identifiantEcole();
    this.isTeacher = this.authService.isTeacher();
  }

  getSchoolYears = (): Observable<SchoolYear[]> => this.crudService.getEntities(this.entity.list);

  updateSchoolYear(data: any) {
    this.payloads = {
      id: data.id,
      fromYear: data.fromYear,
      toYear: data.toYear,
      startDay: data.startDay,
      endDay: data.endDay,
      currentYear: data.currentYear,
    };
    console.log('payload:: ', this.payloads);
    return this.crudService.updateEntity(this.entity.update, this.payloads).pipe();
  }

  addSchoolYear(data: any) {
    this.payloads = {
      fromYear: data.fromYear,
      toYear: data.toYear,
      startDay: data.startDay,
      endDay: data.endDay,
      currentYear: data.currentYear,
    };
    return this.crudService.addEntity(this.entity.add, this.payloads).pipe();
  }

  deleteSchoolYear(id: number) {
    return this.crudService.deleteEntity(this.entity.delete, id).pipe();
  }


  /**
   * Fonction utilitaire pour récupérer les années scolaires futures par rapport à l'année courante.
   *
   * @param {SchoolYear[]} schoolYears - La liste des années scolaires disponibles.
   * @param {boolean} returnList - Si vrai, renvoie la liste des années futures. Si faux, renvoie un booléen indiquant s'il existe des années futures.
   * @returns {SchoolYear[] | boolean} - Renvoie soit un tableau des années futures, soit un booléen indiquant leur existence.
   *
   * @example
   * const schoolYears = [
   *   {
   *     id: 16,
   *     fromYear: "2023",
   *     toYear: "2024",
   *     startDay: "2023-09-01",
   *     endDay: "2024-12-31",
   *     currentYear: true
   *   },
   *   {
   *     id: 17,
   *     fromYear: "2024",
   *     toYear: "2025",
   *     startDay: "2024-09-01",
   *     endDay: "2025-12-31",
   *     currentYear: false
   *   }
   * ];
   * const futureYears = getFutureSchoolYears(schoolYears);
   * console.log(futureYears); // Affiche [{ id: 17, fromYear: "2024", ... }]
   */
  futureSchoolYearHelper(schoolYears: SchoolYear[], sessionYearFromStorage: SchoolYear, returnList: boolean): SchoolYear[] | boolean {
    // Récupérer l'année en session depuis le localStorage
    const sessionYear = sessionYearFromStorage ? parseInt(sessionYearFromStorage.fromYear ?? "0", 10) : null;

    // Trouver l'année courante
    const currentSchoolYear = schoolYears.find(year => year.currentYear);

    // Déterminer la référence de l'année (session ou courante)
    const referenceYear = sessionYear ?? parseInt(currentSchoolYear?.fromYear ?? "0", 10);

    // Si aucune référence n'est trouvée, retourner une valeur appropriée
    if (!referenceYear || isNaN(referenceYear)) {
      return returnList ? [] : false;
    }

    // Filtrer les années futures
    const futureYears = schoolYears.filter(year => {
      const fromYear = year.fromYear ?? "0"; // Si `fromYear` est `undefined`, utiliser "0" comme valeur par défaut
      return parseInt(fromYear, 10) > referenceYear;
    });

    // Retourner la liste ou simplement un booléen si des années futures existent
    return returnList ? futureYears : futureYears.length > 0;
  }


  /**
   * Vérifie s'il existe une année scolaire future par rapport à l'année courante.
   *
   * @param {SchoolYear[]} schoolYears - La liste des années scolaires disponibles.
   * @returns {boolean} - Renvoie true s'il existe une année future, false sinon.
   */
  hasFutureSchoolYear(schoolYears: SchoolYear[], sessionYearFromStorage: SchoolYear,): boolean {
    return this.futureSchoolYearHelper(schoolYears, sessionYearFromStorage, false) as boolean;
  }

  /**
   * Récupère la liste des années scolaires futures par rapport à l'année courante.
   *
   * @param {SchoolYear[]} schoolYears - La liste des années scolaires disponibles.
   * @returns {SchoolYear[]} - Renvoie un tableau des années scolaires futures, sinon un tableau vide s'il n'y en a pas.
   */
  getFutureSchoolYears(schoolYears: SchoolYear[], sessionYearFromStorage: SchoolYear,): SchoolYear[] {
    return this.futureSchoolYearHelper(schoolYears, sessionYearFromStorage, true) as SchoolYear[];
  }


}
