import {Injectable, OnInit} from "@angular/core";
import {UrlConfig} from "../url-config";
import {AuthService} from "./auth.service";
import {CrudService} from "./crud.service";
import {SearchParamsCriteria} from "../models/search-params-criteria";
import {catchError, Observable, of} from "rxjs";
import {AppError} from "../types/app-error";
import {NotificationService} from "../notification.service";
import {Classe} from "../models/classe";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class ClasseService implements OnInit {
  config: any;
  entity: any;
  payloads: any;
  currentUserSchoolId!: number;
  isTeacher!: boolean;

  constructor(private crudService: CrudService,
              private authService: AuthService,
              private notificationService: NotificationService) {
    this.entity = UrlConfig.classe;
  }

  ngOnInit(): void {
    this.currentUserSchoolId = this.authService.identifiantEcole();
    this.isTeacher = this.authService.isTeacher();
  }

  // Dans teacher.service.ts
  getAssignedClasses(teacherId: number, schoolYearId: number) {
    // return this.http.get<Classe[]>(`${this.apiUrl}/assignments/teacher/${teacherId}/year/${schoolYearId}`);
  }


  /**
   * Retourne la liste des classe en fonction  des parametres des recherches
   *
   * @param searchParams parametres de recherches
   */
  getClassroomBySearchParams(searchParams: SearchParamsCriteria): Observable<Classe[]> {
    return this.crudService.getBySearchParams(this.entity.list, searchParams).pipe(
      map((classes: Classe[]) => {
        return classes.sort((a, b) => {
          // Comparaison par numOrder croissant
          const numOrderComparison = (a.numOrder ?? 0) - (b.numOrder ?? 0);
          if (numOrderComparison !== 0) return numOrderComparison;

          // Comparaison par libelle alphabétique
          const libelleComparison = (a.libelle || '').localeCompare(b.libelle || '');
          if (libelleComparison !== 0) return libelleComparison;

          // Comparaison par ID de level croissant si level est défini
          return (a.level?.id ?? 0) - (b.level?.id ?? 0);
        });
      }),
      catchError((error: AppError) => {
        this.notificationService.handleError(error);
        return of([]);
      }),
    );
  }


  cloneClassrooms = (data: any) => this.crudService.addEntity(this.entity.clone, {
    //id: data.id,
    sourceSchoolId: data.sourceSchool,
    sourceYearId: data.sourceSchoolYear,
    sourceSectionId: data.sourceSection,
    targetSchoolId: data.targetSchool,
    targetYearId: data.targetSchoolYear,
    targetSectionId: data.targetSection,
  });

  updateClasse(data: any) {
    const payloads = {id: data.id, ...this.createPayload(data)};
    return this.crudService.updateEntity(this.entity.update, payloads);
  }

  addClasse(data: any) {
    const payloads = this.createPayload(data);
    return this.crudService.addEntity(this.entity.add, payloads);
  }

  ordonnerClasses(data: any[]) {
    this.payloads = {
      classesPlusNumeroOrdre: data
    };
    return this.crudService.updateEntity(this.entity.ordonner, this.payloads).pipe();
  }

  deleteClasse(id: number) {
    return this.crudService.deleteEntity(this.entity.delete, id).pipe();
  }

  search = (searchTerm: string) =>
    this.crudService.getEntities(
      this.authService.checkEndPoint(this.entity.listby.search + '/' + searchTerm),
    );

  // Méthode pour filtrer et trier la liste des salles de classe
  filterAndSortClassrooms(classrooms: Classe[], selectedClassroom: Classe): Classe[] {
    const selectedNumOrder = selectedClassroom.numOrder;

    return classrooms
      .filter(classroom =>
        (classroom.id !== selectedClassroom.id || classroom.code !== selectedClassroom.code) && // Exclure la classe sélectionnée
        (classroom.numOrder !== selectedNumOrder && // Exclure les classes avec le même numOrder
          (classroom.numOrder ?? 0) == (selectedNumOrder ?? 0) + 1) // uniquement les classes avec numOrder +1
      )
      .sort((a, b) => {
        // Tri par numOrder croissant d'abord
        const numOrderDiff = (a.numOrder ?? 0) - (b.numOrder ?? 0);
        if (numOrderDiff !== 0) {
          return numOrderDiff;
        }

        // Si numOrder est égal, trier par libellé de façon ascendante
        const labelDiff = a.libelle.localeCompare(b.libelle);
        if (labelDiff !== 0) {
          return labelDiff;
        }

        // Si libellé est égal, trier par code de façon ascendante
        return (a.code ?? '').localeCompare(b.code ?? '');
      });
  }

// Méthode pour filtrer et trier la liste des salles de classe en excluant celles avec un numOrder différent de selectedClassroom
  filterAndSortClassroomsWithSameNumOrder(classrooms: Classe[], selectedClassroom: Classe): Classe[] {
    const selectedNumOrder = selectedClassroom.numOrder;

    return classrooms
      .filter(classroom =>
        classroom.numOrder === selectedNumOrder // Inclure seulement les classes avec le même numOrder
      )
      .sort((a, b) => {
        // Tri par libellé de façon ascendante en premier
        const labelDiff = a.libelle.localeCompare(b.libelle);
        if (labelDiff !== 0) {
          return labelDiff;
        }

        // Si libellé est égal, trier par code de façon ascendante
        return (a.code ?? '').localeCompare(b.code ?? '');
      });
  }


  private createPayload(data: any) {
    const {
      libelle,
      code,
      hardwareId,
      targetSchool: schoolId,
      level: levelId,
      option: optionId,
      targetSchoolYear: schoolYearId,
      registre,
      amount,
      examClass
    } = data;
    return {
      libelle,
      code,
      hardwareId,
      schoolId,
      levelId,
      optionId,
      schoolYearId,
      registre,
      amount,
      isExamClass: examClass
    };
  }
}
